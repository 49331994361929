.footer-container {
    padding: 0;
    margin: 0;
    color: black !important;

}

.footer-container .upper-footer {
    background-color: #fff;
    padding: 60px 0 64px 0;
}

.footer-container .upper-footer li,
a {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #232445;
    margin-bottom: 10px;
}

.footer-container .upper-footer .inplay-staticmenu-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.4;
    color: #232445;
    margin-bottom: 10px;
}

.footer-container .upper-footer .footer-right-container {
    display: flex;
    justify-content: center;
}


.btn-header {
    background-color: #EE4928;
    padding: 13px 29px;
    line-height: 17px;
    font-size: 14px;
}

.footer-container .upper-footer .widget-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
    color: #232445;
    margin-bottom: 10px;
}

.lower-footer {
    background-color: #232445;
}