@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;500;600&family=Poppins:wght@400;500;600&family=Rajdhani:wght@400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

a {
  text-decoration: none;
}