.marquee__container {
    height: 100%;
    background-color: #ee4928;
    display: flex;
    align-items: center;

}

.marquee__wrapper {
    padding: .4em;
    margin: auto 0;
    min-height: 100%;
}

.maquee-text {
    margin: auto 0;
    min-height: 50px;
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 1.2;
    color: #fff;
    margin-bottom: 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
}

/* .marquee__wrapper {
    animation: 5.5s linear infinite;
} */

.experience__container {
    background-color: #232445;
    padding: 64px 0;
}

.experience__wrapper {
    margin: 0 2.304% 20px;
}

.experience__container .experience__wrapper {
    max-width: 1120.8px;
}

.experience__container .fusion-title {
    flex-grow: 0;
    padding: 0;
    font-size: 70px;
    color: #fff;
    font-weight: 700;
}


.experience__container .fustion-title-par {
    font-size: 19px;
    line-height: 1.3;
    color: var(color1);
    margin-top: 0px;
    margin-bottom: 1em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
}

.fusion-button {
    padding: 6px 13px;
    background-color: #32ba7c;
    /* color: #000; */
    font-size: 19px;
    border-radius: 6px;
}


/* download */

.download__container,
.play__container {
    background-color: #0f1732;
    padding: 64px 0;
}

/* .download__wrapper {
    margin: 0 2.304% 20px;
} */

.download__container .experience__wrapper {
    max-width: 1120.8px;
}

.download__container .fusion-title {
    flex-grow: 0;
    padding: 0;
    font-size: 35px;
    color: #fff;
    font-weight: 700;
}


.download__container .fustion-title-par {
    font-size: 19px;
    line-height: 1.3;
    color: var(color1);
    margin-top: 0px;
    margin-bottom: 1em;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
}

.download__list {
    color: #fff;
    margin-bottom: 0em;
    font-size: 1.2em;
}

.download__list .title-heading-left {
    margin-bottom: 0em;
    font-size: 19px;
}

/* download end */

/* Statistics */
.datalist__container {
    background-color: #232445;
    padding: 78px 0;
}

.bg-card-color {
    width: auto;
    background-color: #32ba7c;
    ;
}

.card-text.counter-box-counter {
    font-size: 31px;
}

.card .card-title.counter-box-counter-percentage {
    font-size: 71px;
}

/* Statistics end */


/* play */
.title .play__title {
    font-size: 48px;
}

.pay__container__right {
    /* width: 563px; */
    min-height: auto;
}

.play__content__right {
    width: 563px;
    height: auto;
}

.right__col .play__content__right .title-heading-left {
    font-size: 18px;
}

.right__col .play__content__right {
    padding-top: 20px;
    padding-right: 25px;
    padding-bottom: 20px;
    padding-left: 25px;
    overflow: hidden;
    color: var(custom_color_4);
    size: cover;
    border-radius: 12px 12px 12px 12px;
    background-color: #0c0d23;
}

/* play end */


/* Premium and Start */
.Premium-Start__container {
    background-color: #232445;
    padding: 50px 20px 4em 20px;
}

.Premium-Start__container .view-more-link {
    color: #ee4928;
    font-weight: 500;
    line-height: 1;
    display: inline-block;
}

/* alert section */
.alert-section {
    background-color: #ee4928;
    ;
}

/* alert section end */

@media screen and (max-width: 1080px) {
    .experience__container .experience__wrapper {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .experience__container .fusion-title {
        font-size: 30px;
    }

    .experience__container .title-heading-left {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
    }

    .fusion-button {
        width: auto;
    }


    .download__container .experience__wrapper {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .download__container .fusion-title {
        font-size: 30px;
    }

    .download__container .title-heading-left {
        text-align: start;
        font-size: 20px;
        font-weight: 500;
    }

}