.header__container {
    background-color: #232445;

}

.navbar-nav .nav-link {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.collapse.navbar-collapse .btn-header{
    background-color: #EE4928;
    padding: 13px 29px;
    line-height: 17px;
    font-size: 14px;
}